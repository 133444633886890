import "./App.css";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./components/support";
import ResponsiveManager from "./components/ResponsivenessManager";
import AboutUs from "./components/about";
import { EmailVarificationComponent } from "./components/email-varification/Index";
import { LoginComponent } from "./components/login/Index";
import Registration from "./components/registration/Registration";
import { ApplyComponent } from "./components/apply/apply";
import ApplicantProfile from "./components/user-profile/applicant_profile";
import DashBoard from "./components/admin";
import MainDashboard from "./components/admin/MainDashboard";
import ApplicationComponent from "./components/admin/applicationComponent";
import AdminLoginComponent from "./components/admin/loginComponent";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedAdminRoute from "./components/admin/ProtectedAdminRoute";
import FinancialTab from "./components/admin/financeTab";
import { PayNow } from "./components/payment/pay";
import { ForgetPasswordComponent } from "./components/forget-password/Index";
import UsersTab from "./components/admin/users_component";
import SiteAdminTab from "./components/admin/site_admin_component";
import CreateUserTab from "./components/admin/create_users";
import ProtectedUrlRoute from "./components/admin/protectedUrlRoute";
import AdmissionTab from "./components/admin/admissionComponents";
import { AdmissionComponent } from "./components/registration/stage-one/admission";
import ValidationComponent from "./components/registration/validation/validator";
import StudentPortal from "./components/student-portal/portal";
import { AcceptanceComponent } from "./components/registration/acceptance-fee/acceptance";
import StudentProfile from "./components/admin/student_profile";
import IdImageUpload from "./components/idcard/upload";
import PinAuthComponent from "./components/PinAuthComponent";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ResponsiveManager />} />
        <Route path="login" element={<LoginComponent />} />
        <Route
          path="payment"
          element={
            <ProtectedRoute>
              <PayNow />
            </ProtectedRoute>
          }
        />

        <Route
          path="auth"
          element={
            <ProtectedRoute>
              <PinAuthComponent />
            </ProtectedRoute>
          }
        />

        <Route
          path="registration"
          element={
            <ProtectedRoute>
              <Registration />
            </ProtectedRoute>
          }
        />
        <Route
          path="reg-1"
          element={
            <ProtectedRoute>
              <AdmissionComponent />
            </ProtectedRoute>
          }
        />

        <Route
          path="validation"
          element={
            <ProtectedRoute>
              <ValidationComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="portal"
          element={
            <ProtectedRoute>
              <StudentPortal />
            </ProtectedRoute>
          }
        />
        <Route
          path="applicant-profile"
          element={
            <ProtectedRoute>
              <ApplicantProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="student-profile"
          element={
            <ProtectedAdminRoute>
              <ProtectedUrlRoute>
                <StudentProfile />
              </ProtectedUrlRoute>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="acceptance"
          element={
            <ProtectedRoute>
              <AcceptanceComponent />
            </ProtectedRoute>
          }
        />
        <Route path="admin-login" element={<AdminLoginComponent />} />

        <Route
          path="admin"
          element={
            <ProtectedAdminRoute>
              <ProtectedUrlRoute>
                <DashBoard />
              </ProtectedUrlRoute>
            </ProtectedAdminRoute>
          }
        >
          <Route
            path=""
            element={
              <ProtectedUrlRoute>
                <MainDashboard />
              </ProtectedUrlRoute>
            }
          />
          <Route
            path="application"
            element={
              <ProtectedUrlRoute>
                <ApplicationComponent />
              </ProtectedUrlRoute>
            }
          />
          <Route
            path="admission"
            element={
              <ProtectedUrlRoute>
                <AdmissionTab />
              </ProtectedUrlRoute>
            }
          />
          <Route
            path="finance"
            element={
              <ProtectedUrlRoute>
                <FinancialTab />
              </ProtectedUrlRoute>
            }
          />
          <Route
            path="users"
            element={
              <ProtectedUrlRoute>
                <UsersTab />
              </ProtectedUrlRoute>
            }
          />
          <Route
            path="create-staff"
            element={
              <ProtectedUrlRoute>
                <CreateUserTab />
              </ProtectedUrlRoute>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedUrlRoute>
                <SiteAdminTab />
              </ProtectedUrlRoute>
            }
          />
        </Route>
        <Route path="contact" element={<ContactUs />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="varify-email" element={<EmailVarificationComponent />} />
        <Route path="apply" element={<ApplyComponent />} />
        <Route path="pay" element={<PayNow />} />
        <Route path="forget-password" element={<ForgetPasswordComponent />} />
        <Route path="upload" element={<IdImageUpload />} />
      </Routes>
    </div>
  );
}

export default App;
