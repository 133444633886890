import * as React from "react";
import Paper from "@mui/material/Paper";
import "../admin/css/style.css";
import {
  MDBBtn,
  MDBCardBody,
  MDBCardText,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import request from "superagent";
import DateInput from "../DateInput";
import { programmes } from "../Arrays.js";
import { loader } from "../LoadingSpinner.js";
import Swal from "sweetalert2";
import { Toast } from "../errorNotifier.js";
import dayjs from "dayjs";
import ReactSearchBox from "react-search-box";
import { downloadExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router-dom";

export default function ApplicationComponent() {
  const [rows, setRows] = useState([]);
  const [init, setInit] = useState(false);
  const [applicationOpeningDate, setApplicationOpeningDate] =
    useState("01/01/2024");
  const [applicationClosingDate, setApplicationClosingDate] =
    useState("01/04/2024");

  const [applicationFees, setApplicationFees] = useState(0);
  const [schoolFees, setSchoolFees] = useState(0);
  const [acceptanceFee, setAcceptanceFee] = useState(0);

  const [courses, setCourses] = useState(programmes[0].programs);
  const [programme, setProgramme] = useState(programmes[0].name);
  const [course, setCourse] = useState("");
  const [eventMsg, setEventMessage] = useState("");
  const [eventDate, setEventDate] = useState("01/01/2024");
  const [eventStatus, setEventStatus] = useState("Open");
  const [noteToApplicant, setNoteToApplicant] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [studentsIdCardData, setStudentsIdCardData] = useState([]);
  const [managerUploadData, setManagerUploadData] = useState([]);
  const [cbtUploadData, setCBTUploadData] = useState([]);

  const columns = [
    { id: "sn", label: "SN", minWidth: 20 },
    { id: "refrance", label: "Applicant ID", minWidth: 20 },
    { id: "ApplicantName", label: "Applicant Name", minWidth: 100 },
    { id: "ApplicantNumber", label: "Applicant Number", minWidth: 70 },
    { id: "totalDiscount", label: "Applicant Email", minWidth: 50 },
    { id: "Payment", label: "Paid", minWidth: 50 },
    { id: "subTotalAmount", label: "Verified", minWidth: 50 },
    { id: "date", label: "Applied", minWidth: 50 },
  ];

  useEffect(() => {
    if (!init) {
      handleDataFetch();
    }
  }, [init]);

  const handleDelete = (index, e) => {
    e.preventDefault();
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleAddRow = (Programme, Course) => {
    const newRow = { Programme, Course };
    setRows((prevRows) => [...prevRows, newRow]);
  };

  const handleDataFetch = async () => {
    try {
      const basicResponse = await request.get(
        "https://api.mcchstfuntua.edu.ng/admin/application.php"
      );
      const basicDetails = basicResponse.body.details;

      setApplicationFees(basicDetails.ApplicationFees);
      setAcceptanceFee(basicDetails.AcceptanceFee);
      setSchoolFees(basicDetails.SchoolFees);
      setApplicationOpeningDate(basicDetails.ApplicationOpening);
      setApplicationClosingDate(basicDetails.ApplicationClosing);
      setEventDate(basicDetails.EventDate);
      setEventMessage(basicDetails.EventMessage);
      setEventStatus(basicDetails.EventStatus);
      setNoteToApplicant(basicDetails.NoteToApplicant);

      const coursesResponse = await request.get(
        "https://api.mcchstfuntua.edu.ng/admin/courses_on_offer.php"
      );
      setRows(coursesResponse.body);

      const preApplicantsResponse = await request.get(
        "https://api.mcchstfuntua.edu.ng/admin/get_pre_application_applicants.php"
      );
      setApplicants(preApplicantsResponse.body);

      const applResponse = await request.get(
        "https://api.mcchstfuntua.edu.ng/admin/get_applicants.php"
      );
      const regApplicantsList = applResponse.body?.application || [];
      const regApplicantsCourses = applResponse.body?.course_details || [];
      const regOtherDetails = applResponse.body?.other_details || [];

      let constrStudentsIDArray = regApplicantsList.map((e, i) => ({
        SN: i + 1,
        Name: `${e.FirstName} ${e.Surname} ${e.OtherName}`,
        Code: "",
        State: e.State,
        Gender: e.Gender,
        BloodGroup: "",
        Course: "",
        Mobile: e.PhoneNumber,
        DOB: e.DoB,
        Photo: "",
      }));

      let constrManagerDataArray = regApplicantsList.map((e, i) => ({
        "Mat. No.": "",
        "Application No.": e.ApplicationId,
        Name: `${e.FirstName} ${e.Surname} ${e.OtherName}`,
        Address: e.Address,
        Email: e.Email,
        Department: "",
        ControlAccount: "",
        State: e.State,
        LGA: e.LGA,
        Level: "",
        Session: "",
        Gender: e.Gender,
        Religion: "",
        MaritalStatus: e.MaritalStatus,
        Programme: "",
        StudentMobile: e.PhoneNumber,
        ParentMobile: regOtherDetails[i]?.ParentOrGuardianAddress || "",
        DOB: e.DoB,
      }));

      let constrCBTDataArray = regApplicantsList.map((e, i) => ({
        SNO: i + 1,
        "Serial No.": e.ApplicationId,
        Fullname: `${e.FirstName} ${e.Surname} ${e.OtherName}`,
        Gender: e.Gender,
        "State of Origin": e.State,
        "LGA of Origin": e.LGA,
        Email: e.Email,
        "Phone number": e.PhoneNumber,
        "Mode of Entry": "Fresh",
        "First Choice": regApplicantsCourses[i]?.FirstChoiceProgramme || "",
        "Second Choice": regApplicantsCourses[i]?.SecondChoiceProgramme || "",
        OLevel1: e.Scores,
        OLevel2: e.Scores,
      }));

      setManagerUploadData(constrManagerDataArray);
      setStudentsIdCardData(constrStudentsIDArray);
      setCBTUploadData(constrCBTDataArray);

      setInit(true);
    } catch (err) {
      console.error("Error fetching data: ", err);
    }
  };

  const handleIdDownloadExcel = () => {
    const idHeader = [
      "SN",
      "Name",
      "Code",
      "State",
      "Gender",
      "Blood Group",
      "Course",
      "Mobile Number",
      "DOB",
      "Photo",
    ];

    downloadExcel({
      fileName: "Students ID card data",
      sheet: "Students ID Card",
      tablePayload: {
        header: idHeader,
        body: studentsIdCardData,
      },
    });
  };

  const handleManagerDownloadExcel = () => {
    const managerHeader = [
      "Mat.No.",
      "Application No.",
      "Name",
      "Address",
      "Email",
      "Department",
      "ControlAccount",
      "State",
      "LGA",
      "Level",
      "Session",
      "Gender",
      "Religion",
      "Marital Status",
      "Programme",
      "Student Mobile Number",
      "Parent Mobile Number",
      "Date of Birth",
    ];

    downloadExcel({
      fileName: "Manager data",
      sheet: "Manager data",
      tablePayload: {
        header: managerHeader,
        body: managerUploadData,
      },
    });
  };

  const handleCBTDownloadExcel = () => {
    const cbtHeader = [
      "SNO",
      "Serial No.",
      "Fullname",
      "Gender",
      "State of Origin",
      "LGA of Origin",
      "Email",
      "Phone number",
      "Mode of Entry",
      "First Choice",
      "Second Choice",
      "OLevel1",
      "OLevel2",
    ];

    downloadExcel({
      fileName: "CBT data",
      sheet: "CBT data",
      tablePayload: {
        header: cbtHeader,
        body: cbtUploadData,
      },
    });
  };

  const saveBasicData = async () => {
    const data = {
      ApplicationOpening: applicationOpeningDate,
      ApplicationClosing: applicationClosingDate,
      AdmissionOpening: "01/04/2024",
      AdmissionClosing: "01/03/2024",
      ApplicationFees: applicationFees,
      SchoolFees: schoolFees,
      AcceptanceFee: acceptanceFee,
      EventMessage: eventMsg,
      EventStatus: eventStatus,
      EventDate: eventDate,
      NoteToApplicant: noteToApplicant,
    };

    if (navigator.onLine) {
      await request
        .post("https://api.mcchstfuntua.edu.ng/admin/save_setup.php")
        .type("application/json")
        .send(data)
        .then((response) => {
          Toast.fire({
            icon: "success",
            title: "updated successfully",
          });
        })
        .catch((err) => {
          let errorText = err.response.text;
          console.log(errorText);

          Swal.fire({
            title: "Error!",
            text: "There is occoured an error",
            icon: "error",
          });
        });
    } else {
      Toast.fire({
        icon: "error",
        title: "No internet connection",
      });
    }
  };

  const saveCoursesOnOffer = async () => {
    console.log("COURSES ON OFFER", rows);

    if (navigator.onLine) {
      // progress spinner
      loader({
        title: "Updating",
        text: "Please! wait.",
      });

      await request
        .post("https://api.mcchstfuntua.edu.ng/admin/save_courses_on_offer.php")
        .type("application/json")
        .send(rows)
        .then((response) => {
          const message = response.text;
          console.log(message);
          Toast.fire({
            icon: "success",
            title: "updated successfully",
          });
        })
        .catch((err) => {
          let errorText = err.response.text;
          console.log(errorText);

          Swal.fire({
            title: "Error!",
            text: "There is occoured an error",
            icon: "error",
          });
        });
    } else {
      Toast.fire({
        icon: "error",
        title: "No internet connection",
      });
    }
  };

  return (
    <div className="m-4 d-flex flex-column align-items-center">
      <MDBCardBody>
        <MDBCardText>
          <h4>Students Application Management</h4>
        </MDBCardText>
      </MDBCardBody>
      <Paper className="p-2 w-100">
        <MDBRow>
          <div style={{ fontWeight: "900", padding: "20px" }}>
            APPLICATION SETUP
          </div>
          <MDBRow>
            <MDBCol md={3}>
              <div>Target Opening Date</div>
              <div>
                <DateInput
                  value={applicationOpeningDate}
                  handleValue={(e) => {
                    const formattedDate = dayjs(e).format("DD/MM/YYYY");

                    setApplicationOpeningDate(formattedDate);
                  }}
                />
              </div>
            </MDBCol>
            <MDBCol md={6}>
              <div>Event Message</div>
              <TextField
                value={eventMsg}
                className="center-cmp w-100"
                variant="outlined"
                multiline
                onChange={(e) => {
                  setEventMessage(e.target.value);
                }}
              />
            </MDBCol>
            <MDBCol md={3}>
              <div>Counter Visibility</div>

              <FormControl fullWidth>
                <InputLabel>Choose Status</InputLabel>
                <Select
                  value={eventStatus}
                  label="Choose Programme"
                  onChange={(e) => {
                    setEventStatus(e.target.value);
                  }}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Close">Close</MenuItem>
                </Select>
              </FormControl>
            </MDBCol>
          </MDBRow>
        </MDBRow>

        <MDBRow>
          <MDBRow>
            <MDBCol md={3}>
              Target closing date
              <div>
                <DateInput
                  value={applicationClosingDate}
                  handleValue={(e) => {
                    const formattedDate = dayjs(e).format("DD/MM/YYYY");

                    setApplicationClosingDate(formattedDate);
                  }}
                />
              </div>
            </MDBCol>
            <MDBCol md={9}>
              <div>Set note to Applicant</div>
              <TextField
                value={noteToApplicant}
                className="center-cmp w-100"
                variant="outlined"
                multiline
                onChange={(e) => {
                  setNoteToApplicant(e.target.value);
                }}
              />
            </MDBCol>
          </MDBRow>
          <div onClick={saveBasicData} className="cus-button w-25 my-2">
            Update
          </div>
        </MDBRow>
      </Paper>

      <Paper className="p-2 w-100 my-2">
        <MDBRow>
          <div style={{ fontWeight: "900", padding: "20px" }}>
            APPLICANTS DATA DOWNLOADS
          </div>
          <MDBRow>
            <MDBCol md={4}>
              <MDBBtn
                onClick={handleManagerDownloadExcel}
                style={{ background: "#05321e" }}
                className="m-btn-color"
              >
                Download for manager
              </MDBBtn>
            </MDBCol>
            <MDBCol md={4}>
              <MDBBtn
                onClick={handleCBTDownloadExcel}
                style={{ background: "#05321e" }}
              >
                Download for CBT
              </MDBBtn>
            </MDBCol>
            <MDBCol md={4}>
              <MDBBtn
                onClick={handleIdDownloadExcel}
                style={{ background: "#05321e" }}
              >
                Students ID Card
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBRow>
      </Paper>

      <MDBRow style={{ padding: "10px" }}>
        <div style={{ fontWeight: "900", padding: "20px" }}>
          COURSES ON OFFER
        </div>

        <MDBCol>
          <MDBRow>
            <div
              style={{
                fontSize: "12px",
                fontWeight: 900,
                textAlign: "left",
                marginBottom: "10px",
                color: "green",
              }}
            >
              Add course by choosing it from the drop down below which will add
              it directly to the offfered course table
            </div>

            <MDBCol>
              <FormControl fullWidth>
                <InputLabel>Choose Programme</InputLabel>
                <Select
                  value={programme}
                  label="Choose Programme"
                  onChange={(e) => {
                    setProgramme(e.target.value);

                    programmes.map((prog, i) => {
                      if (prog.name === e.target.value) {
                        setCourses(programmes[i].programs);
                        // setCourse(programmes[i].programs[0].name);
                        return; // exit the loop after finding the matching program
                      }
                    });
                  }}
                >
                  {programmes.map((prog) => {
                    return <MenuItem value={prog.name}>{prog.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </MDBCol>
            <MDBCol>
              <FormControl fullWidth>
                <InputLabel>Choose Course</InputLabel>
                <Select
                  value={course}
                  label="Choose Course"
                  onChange={(e) => {
                    setCourse(e.target.value);

                    handleAddRow(programme, e.target.value);
                  }}
                >
                  {courses.map((cse) => {
                    return <MenuItem value={cse.name}>{cse.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </MDBCol>
          </MDBRow>

          <MDBCol>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Departments</th>
                    <th>Courses</th>
                    <th>Remove</th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.Programme}</td>
                        <td>{item.Course}</td>
                        <td>
                          <div
                            onClick={(e) => handleDelete(index, e)}
                            className="cus-button"
                            style={{ textAlign: "center" }}
                          >
                            remove
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div onClick={saveCoursesOnOffer} className="cus-button">
                Update
              </div>
            </div>
          </MDBCol>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <Paper className="mt-4" sx={{ width: "100%", overflow: "hidden" }}>
            <div style={{ fontWeight: "900", padding: "20px" }}>
              LIST OF APPLICANT
            </div>
            <div>
              <ReactSearchBox
                leftIcon={
                  <div>
                    <i class="fas fa-magnifying-glass"></i>
                  </div>
                }
                placeholder="search for record"
                value="Doe"
              />
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          // style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicants.map((row, id) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={id}>
                        {<TableCell key={id + 1}>{id + 1}</TableCell>}
                        {
                          <TableCell key={id + 1}>
                            {row.ApplicationId}
                          </TableCell>
                        }
                        {
                          <TableCell key={id + 2}>
                            {row.FirstName + " " + row.Surname}
                          </TableCell>
                        }
                        {<TableCell key={id + 3}>{row.PhoneNumber}</TableCell>}
                        {<TableCell key={id + 4}>{row.Email}</TableCell>}
                        {<TableCell key={id + 5}>{row.Paid}</TableCell>}
                        {<TableCell key={id + 6}>{row.eVarified}</TableCell>}
                        {<TableCell key={id + 7}>{row.IsRegistered}</TableCell>}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={applicants.length}
              rowsPerPage={10}
              page={10}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
